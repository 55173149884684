<template>
  <div class="container">
    <el-card>
      <div class="pro-date mb-1">
        <span>
          {{ $t("plans.currentPlan") }}:
          <strong v-if="isPro" class="text-success ml-2 current-plan"
            ><i class="fas fa-crown"></i> Pro</strong
          >
          <strong v-else class="text-success ml-2 current-plan">Free</strong>
        </span>
      </div>
      <div class="pro-date mb-3" v-if="isPro">
        <span class="text-info plan-duration">{{
          `${$t("plans.planDuration")} ${proTimeLimited} (GMT+8)`
        }}</span>
      </div>
      <div class="is-Pro">
        <hr />
        <div class="row text-left">
          <div class="col-sm-6">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.accessOnAnyDevice") }}
            <span class="mr-1"><i class="fas fa-mobile-alt"/></span>
            <span class="mr-1"><i class="fas fa-tablet-alt"/></span>
            <span class="mr-1"><i class="fas fa-desktop"/></span>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.practiceAnytime") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.ivyWayToefl") }}
          </div>
          <div class="col-sm-6" v-show="!isPro">
            <span class="text-danger mr-1"><i class="fas fa-check"></i></span>
            {{ $t("plans.twoFullTestsAndFivePractices") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.unlimitedDailyPractices") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.customVocabLists") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.adFree") }}
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.freeToeflMaterials") }}
            <el-tooltip
              :content="$t('plans.downloadAllMaterials')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.freeTestTakersScoreReport") }}
            <router-link :to="{ name: 'ToeflScorereport' }">
              <i class="fas fa-question-circle" />
            </router-link>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.priorityGrading") }}
            <el-tooltip
              :content="$t('plans.priorityGradingTooltip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
          <div class="col-sm-6">
            <span class="text-danger mr-1" v-show="isPro"
              ><i class="fas fa-check"></i
            ></span>
            <span class="mr-1" v-show="!isPro"
              ><i class="fas fa-times"></i
            ></span>
            {{ $t("plans.discountForGradingServices") }}
            <el-tooltip
              v-if="langIsEn"
              :content="$t('plans.discountForGradingServicesTooltip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Plans from "@/apis/plans";
import { instant, user } from "@ivy-way/material";
export default {
  components: {},
  mixins: [],
  props: ["isPro", "proTimeLimited"],
  data() {
    return {
      subscriptions: []
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    langIsEn() {
      return this.$store.getters["user/langShortValue"] === "en";
    },
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    }
  },
  async created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped>
.current-plan {
  font-size: 1.5rem;
}
.timedLimited {
  color: #00000077;
}
.is-Pro .col-sm-6 {
  margin-bottom: 0.5rem;
}
.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}
.container {
  max-width: 800px;
  padding: 20px 0;
}
::v-deep .el-card__body {
  /* padding: 0;
  padding-left: 20px;
  padding-right: 10px; */
}
.pro-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
}
.my-subscription {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.pro-date span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-info {
  /* width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center; */
  font-size: 14px;
  color: #409eff !important;
}
.text-info.plan-duration {
  color: #aaa !important;
}
.pro-status {
  display: flex;
  align-items: center;
  padding-right: 0.2rem;
}

@media screen and (max-width: 1220px) {
  .my-subscription {
    display: block;
    text-align: left;
    /* justify-content: space-between; */
    /* align-items: center; */
  }
}
.col-sm-6 > .mr-1 {
  display: inline-block;
  width: 1rem;
}
.text-danger {
  color: red !important;
}
</style>
