<template>
  <div>
    <el-card shadow="never">
      <h3 class="title text-left" v-html="$t('userProfile.myBestScore')"></h3>
      <div class="row">
        <div class="col-lg-6" v-if="user_score">
          <ScoreCard
            :user_score="user_score"
            :scoreDate="scoreDate"
            :reading="false"
            :listening="false"
            :speaking="false"
            :writing="false"
          ></ScoreCard>
        </div>
        <div class="col-lg-6" v-if="best_score">
          <BestScoreCard
            :user_score="best_score"
            class="text-left"
          ></BestScoreCard>
        </div>
      </div>
    </el-card>
    <el-card shadow="never" style="margin:20px 0">
      <h3 class="title text-left">
        {{ $t("userProfile.My Performance Breakdown") }}
      </h3>
      <Performance
        v-show="readingTags && readingTags.length > 0"
        :tags="readingTags"
        section="Reading"
      ></Performance>
      <Performance
        v-show="listeningTags && listeningTags.length > 0"
        :tags="listeningTags"
        section="Listening"
      ></Performance>
    </el-card>
  </div>
</template>

<script>
import "@/views/toefl/transcript/style/reportcard.css";
import _ from "lodash";
import { mapGetters } from "vuex";
import BestScoreCard from "@/views/toefl/transcript/BestScoreCard";
import ScoreCard from "@/views/toefl/transcript/ScoreCard";
import Performance from "@/components/userProfile/Performance";
import User from "@/apis/user.js";
export default {
  components: {
    BestScoreCard,
    Performance,
    ScoreCard
  },
  mixins: [],
  props: [],
  data() {
    return {
      best_score: null,
      user_score: null,
      analysis: [],
      scoreDate: ""
    };
  },
  async mounted() {
    let res = null;
    if (this.$route.params.id) {
      res = await User.getStudentTestAnalysis({
        user_id: this.$route.params.id
      });
    } else {
      res = await User.getStudentTestAnalysis();
    }
    if (res) {
      this.analysis = res.analysis;
      this.user_score = res.toefl.max_total;
      this.scoreDate = res.toefl.max_total
        ? res.toefl.max_total.created_at
        : null;
      this.best_score = {
        max_reading: res.toefl.max_reading || null,
        max_listening: res.toefl.max_listening || null,
        max_speaking: res.toefl.max_speaking || null,
        max_writing: res.toefl.max_writing || null
      };
    }
  },
  methods: {},
  computed: {
    ...mapGetters("profile", ["getUserData", "getStudentDataAfterPatch"]),
    readingTags() {
      let tags = _.filter(this.analysis, ["type", "toefl-reading"]);
      return tags;
    },
    listeningTags() {
      let tags = _.filter(this.analysis, ["type", "toefl-listening"]);
      return tags;
    }
  }
};
</script>

<style scoped>
.el-card {
  background-color: #f2f3f5;
}
.btn-edit:focus {
  outline: none;
}
.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}
</style>
