<template>
  <div class="container-fluid container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item :to="{ name: 'ToeflStudents' }">
          {{ $t("header.students") }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ formattedUserName }}</el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div style="margin:20px auto" class="text-center">
      <el-card class="box-card">
        <div class="userName">
          <PageTitle>{{ formattedUserName }}</PageTitle>
          <hr />
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
            <BasicInfo :userDataByAdmin="userDataByAdmin"></BasicInfo>
          </el-tab-pane>
          <el-tab-pane :label="$t('userProfile.Testing')" name="Testing">
            <Testing v-if="showTesting" style="margin:20px 0"></Testing>
          </el-tab-pane>
          <template v-if="CompanyName === 'TestAdmit'">
            <el-tab-pane
              :label="$t('userProfile.T-Coins')"
              name="T-Coins"
              v-if="roles.includes('student')"
            >
              <Balance
                style="margin:20px 0"
                :point="point"
                :showLink="false"
              />
            </el-tab-pane>
            <el-tab-pane
              :label="$t('userProfile.Subscription')"
              name="Subscription"
              v-if="roles.includes('student')"
            >
              <SubscriptionAdmin
                :isPro="getIsPro"
                :proTimeLimited="getProTimeLimited"
              ></SubscriptionAdmin>
            </el-tab-pane>
          </template>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import PageTitle from "@/components/PageTitle.vue";
import BasicInfo from "@/components/userProfile/BasicInfo";
import Testing from "@/components/userProfile/Testing";
import Balance from "@/views/toefl/transcript/components/balance";
// import Subscription from "@/components/userProfile/Subscription";
import SubscriptionAdmin from "@/components/userProfile/SubscriptionAdmin";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.formattedUserName} Profile -` + this.CompanyName
    };
  },
  components: {
    Breadcrumb,
    PageTitle,
    BasicInfo,
    Testing,
    Balance,
    // Subscription,
    SubscriptionAdmin
  },
  mixins: [roleMixin],
  data() {
    return {
      id: null,
      activeName: "info",
      isActive: null,
      roles: [],
      collectedCounselingSchools: [],
      teacherBillingInfos: [],
      taken_unlimited: null,
      point: 0,
      showTesting: false
    };
  },
  async created() {
    await this.initialProfile();
    if (this.$route.params.editStudentInfoDialog) {
      this.activeName = "student";
    }
  },
  computed: {
    getIsPro() {
      const roles = this.roles || [];
      return roles.includes("pro") || roles.includes("old-pro");
    },
    getProTimeLimited() {
      return (this.taken_unlimited_expire_at || "").split(" ")[0];
    },
    userDataByAdmin() {
      return this.$store.getters["profile/getUserDataByAdmin"];
    },
    profileStudentKey() {
      return JSON.stringify(this.userDataByAdmin.student);
    },
    profileTeacherKey() {
      return JSON.stringify(this.userDataByAdmin.instructor);
    },
    formattedUserName() {
      return this.userMethods.displayName(
        this.userDataByAdmin.basic_info?.first_name,
        this.userDataByAdmin.basic_info?.last_name
      );
    },
    userTags() {
      return this.userDataByAdmin.basic_info?.tags;
    },
    userMethods() {
      return user;
    }
  },
  methods: {
    handleClick(tab) {
      if (tab.name === "Testing") {
        this.showTesting = true;
      }
    },
    async initialProfile() {
      if (this.$route.params.id) {
        await this.$store.dispatch(
          "profile/getUserDataByAdmin",
          this.$route.params.id
        );
      } else if (this.$route.query.username) {
        await this.$store.dispatch(
          "profile/getUserDataForAdminByUserName",
          this.$route.query.username
        );
      }
      this.roles = this.userDataByAdmin.basic_info.all_roles || [];
      this.taken_unlimited_expire_at = this.userDataByAdmin.basic_info.taken_unlimited_expire_at;
      this.point = this.userDataByAdmin.basic_info.point;

      if (this.roles.includes("parent")) {
        await this.$store.dispatch("currency/fetchExchangeRate");
        this.$store.dispatch("profile/calculateDisplayBalance", {
          parentId: this.userDataByAdmin.basic_info.id,
          balance: this.userDataByAdmin.basic_info.account_balance,
          balanceCurrency: this.userDataByAdmin.basic_info
            .account_balance_currency
        });
      }
      this.id = this.userDataByAdmin.basic_info.id;
    }
  }
};
</script>

<style scoped>
.userName {
  width: 100%;
  text-align: left;
}

.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.titleLine {
  text-align: center;
}
.titleStyle {
  display: block;
  overflow: auto;
}
.primaryColor {
  color: var(--themeColor);
}
.container-fluid {
  padding: 0px 20px;
  max-width: 95vw;
}
@media (max-width: 992px) {
  .container-fluid {
    padding: 0;
  }
}
::v-deep .el-tabs__nav-scroll {
  overflow: auto;
}
</style>
