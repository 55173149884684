<template>
  <div>
    <br />
    <el-card shadow="never">
      <el-dialog
        :title="$t('userProfile.basic.warning')"
        :visible.sync="passwordDialogVisible"
        width="80%"
      >
        <span>{{ $t("userProfile.basic.askChangePass") }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordDialogVisible = false">{{
            $t("userProfile.basic.cancel")
          }}</el-button>
          <el-button type="primary" @click="changePassword">{{
            $t("userProfile.basic.change")
          }}</el-button>
        </span>
      </el-dialog>
      <el-row>
        <div class="pb-3 d-flex">
          <span class="pt-1 pr-2">
            <h3 class="title">{{ $t("userProfile.basic.title") }}</h3>
          </span>
          <el-tooltip
            class="item"
            :content="$t('userProfile.dialog.edit')"
            placement="top"
            popper-class="tooltipColor"
          >
            <el-button
              class="btn-edit"
              @click="
                editDialog = true;
                type = 'basicInfo';
              "
              type="text"
            >
              <i class="el-icon-edit"></i>
            </el-button>
          </el-tooltip>
        </div>
        <div class="pl-2 mb-4">
          <el-avatar shape="square" :size="70" :src="squareUrl"></el-avatar>
        </div>
      </el-row>
      <template v-if="editDialog">
        <EditDialogBasicAdmin
          v-if="userDataByAdmin"
          :type="type"
          :editDialog="editDialog"
          :profile="profile"
          :id="$route.params.id"
          @updateDialog="updateDialog"
        ></EditDialogBasicAdmin>
        <EditDialogBasic
          v-else
          :type="type"
          :editDialog="editDialog"
          :profile="profile"
          @updateDialog="updateDialog"
        ></EditDialogBasic>
      </template>
      <el-table :data="basicTableData" :show-header="false">
        <el-table-column width="auto">
          <template slot-scope="scope">{{ $t(scope.row.name) }}</template>
        </el-table-column>

        <el-table-column prop="data" width="auto">
          <template slot-scope="scope">
            <template v-if="scope.row.propertyName === 'password'">
              <el-button
                v-if="!userDataByAdmin"
                @click="passwordDialogVisible = true"
                class="btn-edit"
                type="text"
                size="small"
                >{{ $t("userProfile.basic.changePass") }}</el-button
              >
              <span v-else><i class="fas fa-eye-slash"></i></span>
            </template>
            <template v-else>
              <slot>{{ scope.row.data }}</slot>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="auto"></el-table-column>
      </el-table>
    </el-card>
    <br />
    <el-card shadow="never">
      <el-row>
        <div class="pb-3 d-flex">
          <span class="pt-1 pr-2">
            <h3 class="title">{{ $t("userProfile.basic.contactInfo") }}</h3>
          </span>
          <el-tooltip
            class="item"
            :content="$t('userProfile.dialog.edit')"
            placement="top"
            popper-class="tooltipColor"
          >
            <el-button
              class="btn-edit"
              @click="
                editDialog = true;
                type = 'contactInfo';
              "
              type="text"
            >
              <i class="el-icon-edit"></i>
            </el-button>
          </el-tooltip>
        </div>
      </el-row>
      <el-table :data="contactTableData" :show-header="false">
        <el-table-column prop="name" width="auto">
          <template slot-scope="scope">{{ $t(scope.row.name) }}</template>
        </el-table-column>
        <el-table-column prop="data" width="auto"></el-table-column>
        <el-table-column width="auto"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditDialogBasic from "@/components/userProfile/EditDialogBasic";
import EditDialogBasicAdmin from "@/components/userProfile/adminEditUser/EditDialogBasic";
export default {
  components: {
    EditDialogBasic,
    EditDialogBasicAdmin
  },
  props: ["userDataByAdmin"],
  watch: {
    userDataByAdmin() {
      this.profile = this.userDataByAdmin.basic_info;
      if (this.profile.role === "parent") {
        this.parent = this.getUserData.parent;
      }
      if (this.profile.photo_url) {
        this.squareUrl = this.profile.photo_url;
      } else {
        this.squareUrl = require("@/assets/avatar.png");
      }
      this.assignData();
    }
  },
  data() {
    return {
      squareUrl: "",
      profile: {},
      parent: {},
      type: "",
      basicTableData: [
        {
          name: "userProfile.basic.username",
          data: "",
          edit: true,
          propertyName: "username"
        },
        {
          name: "userProfile.basic.firstName",
          data: "",
          edit: true,
          propertyName: "first_name"
        },
        {
          name: "userProfile.basic.lastName",
          data: "",
          edit: true,
          propertyName: "last_name"
        },
        {
          name: "userProfile.basic.role",
          data: "",
          edit: false,
          propertyName: "role"
        },
        {
          name: "userProfile.basic.password",
          edit: false,
          propertyName: "password"
        }
        // {
        //   name: "userProfile.basic.joined",
        //   data: "",
        //   edit: false,
        //   propertyName: "joined"
        // }
      ],
      studentOfParentTableData: [
        {
          name: "userProfile.basic.children",
          data: "",
          edit: false,
          propertyName: "son_daughter"
        }
      ],
      contactTableData: [
        {
          name: "userProfile.basic.email",
          data: "",
          edit: false,
          propertyName: "email"
        },
        {
          name: "userProfile.basic.phone",
          data: "",
          edit: true,
          propertyName: "phone"
        },
        {
          name: "userProfile.basic.facebook",
          data: "",
          edit: true,
          propertyName: "facebook_id"
        },
        {
          name: "userProfile.basic.line",
          data: "",
          edit: true,
          propertyName: "line_id"
        },
        {
          name: "userProfile.basic.wechat",
          data: "",
          edit: true,
          propertyName: "wechat_id"
        },
        {
          name: "userProfile.basic.skype",
          data: "",
          edit: true,
          propertyName: "skype_id"
        }
      ],
      editDialog: false,
      passwordDialogVisible: false
    };
  },
  mounted() {
    this.profile = this.getUserData.basic_info;
    if (this.userData) {
      this.profile = this.userData.basic_info;
    }
    if (this.profile.role === "parent") {
      this.parent = this.getUserData.parent;
    }
    // check the photo existing or not
    if (this.profile.photo_url) {
      this.squareUrl = this.profile.photo_url;
    } else {
      this.squareUrl = require("@/assets/avatar.png");
    }
    this.assignData();
  },

  methods: {
    async changePassword() {
      this.passwordDialogVisible = false;
      const url = await this.$store.dispatch("profile/changePassword", this.id);
      window.location = url.reset_url;
    },
    async updateDialog(status) {
      this.editDialog = false;
      if (status === "submit") {
        if (this.userDataByAdmin) {
          await this.$store.dispatch(
            "profile/getUserDataByAdmin",
            this.$route.params.id
          );
          this.profile = this.$store.getters[
            "profile/getUserDataByAdmin"
          ].basic_info;
          this.assignData();
        } else {
          this.profile = this.getDataAfterPatch.basic_info;
          this.assignData();
        }
      }
    },
    assignData() {
      this.squareUrl = this.profile.photo_url;
      this.basicTableData[0].data = this.profile.username;
      this.basicTableData[1].data = this.profile.first_name;
      this.basicTableData[2].data = this.profile.last_name;
      this.basicTableData[3].data = this.profile.role;
      // this.basicTableData[4].data = this.profile.joined;

      this.studentOfParentTableData[0].data = this.parent.son_daughter;

      this.contactTableData[0].data = this.profile.email;
      this.contactTableData[1].data = this.profile.phone;
      this.contactTableData[2].data = this.profile.facebook_id;
      this.contactTableData[3].data = this.profile.line_id;
      this.contactTableData[4].data = this.profile.wechat_id;
      this.contactTableData[5].data = this.profile.skype_id;
    },
    async getChildInfo(item) {
      try {
        await this.$store.dispatch(
          "profile/getChildData",
          item.pivot.student_user_id
        );
        this.$router.push({
          name: "StudentProfile",
          params: { studentId: item.pivot.student_user_id }
        });
      } catch (e) {
        // Do nothing
      }
    }
  },
  computed: {
    ...mapGetters("profile", ["getUserData", "getDataAfterPatch"]),
    table() {
      return this.$t("userProfile.basic.test");
    }
  }
};
</script>

<style scoped>
.el-card {
  background-color: #f2f3f5;
}
.btn-edit:focus {
  outline: none;
}
::v-deep .el-avatar {
  display: block;
}
.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}
</style>

<style>
.tooltipColor.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: var(--themeColor);
}
.tooltipColor.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
  border-top-color: var(--themeColor);
}
.tooltipColor {
  background: var(--themeColor) !important;
}
</style>
