<template>
  <!-- Form -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="updateVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <div slot="title">
        <h3>{{ $t("userProfile.dialog.edit") }}</h3>
      </div>

      <template v-if="type === 'basicInfo'">
        <el-form>
          <el-form-item :label="$t('userProfile.basic.photoUpload')">
            <el-upload
              :action="imageAction"
              :headers="token"
              :data="{ path: 'user' }"
              list-type="picture"
              name="image"
              :on-success="handleUploadSuccess"
              :on-remove="removeUploadedPhoto"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">
                {{ $t("userProfile.basic.upload") }}
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-show="false"
            :label="$t('userProfile.basic.username')"
          >
            <el-input
              v-model="newProfile.username"
              autocomplete="off"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.basic.firstName')">
            <el-input
              v-model="newProfile.first_name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.basic.lastName')">
            <el-input
              v-model="newProfile.last_name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>

      <template v-if="type === 'contactInfo'">
        <el-form>
          <el-form-item :label="$t('userProfile.basic.email')">
            <el-input v-model="newProfile.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.basic.phone')">
            <el-input v-model="newProfile.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.basic.facebook')">
            <el-input
              v-model="newProfile.facebook_id"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.basic.line')">
            <el-input
              v-model="newProfile.line_id"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.basic.wechat')">
            <el-input
              v-model="newProfile.wechat_id"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('userProfile.basic.skype')">
            <el-input
              v-model="newProfile.skype_id"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible">{{
          $t("userProfile.dialog.cancel")
        }}</el-button>
        <el-button type="primary" @click="submitChange">{{
          $t("userProfile.dialog.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import JwtService from "@/common/jwt.service";

export default {
  props: ["type", "editDialog", "profile"],
  data() {
    return {
      formLabelWidth: "120px",
      dialogFormVisible: this.editDialog,
      uploadFileId: "",
      fileList: [],
      newProfile: {}
    };
  },
  mounted() {
    this.newProfile = _.cloneDeep(this.profile);
  },
  computed: {
    imageAction() {
      return process.env.VUE_APP_BACKEND_URL + "image";
    },
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    }
  },
  methods: {
    updateVisible() {
      this.dialogFormVisible = false;
      this.$emit("updateDialog", "cancel");
    },
    handleUploadSuccess(response, file, fileList) {
      this.newProfile.photo_url = response.image.url;
      this.fileList = fileList;
      if (this.uploadFileId !== "") {
        this.removeUploadedPhoto(file, fileList);
      }
      this.uploadFileId = response.image.id;
    },
    async removeUploadedPhoto(file, fileList) {
      await this.$store.dispatch(
        "registerDetails/deleteUploadFile",
        this.uploadFileId
      );

      if (fileList.length >= 2) {
        this.fileList = [fileList[1]];
      } else {
        this.fileList = [];
        this.uploadFileId = "";
      }
    },
    async submitChange() {
      this.dialogFormVisible = false;
      await this.$store.dispatch("profile/updateBasicProfile", this.newProfile);
      this.$emit("updateDialog", "submit");
    }
  }
};
</script>

<style></style>
